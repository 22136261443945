<script lang="ts" setup>
const { productUuid } = defineProps<{ productUuid?: string }>();

const { cartItems, addItem, removeItem } = useCart();

const isFavorite = computed(() => {
  if (!productUuid) return false;

  return cartItems.value.includes(productUuid);
});

const toggleFavorite = () => {
  if (!productUuid) return;
  isFavorite.value ? removeItem(productUuid) : addItem(productUuid);
};
</script>

<template>
  <div
    class="group flex justify-center items-center bg-white rounded-full cursor-pointer gap-2.5 p-2.5 lg:p-[18px]"
    @click="toggleFavorite"
  >
    <span
      v-if="!isFavorite"
      class="hidden lg:group-hover:block lg:group-hover:pl-1"
      >Add to favourites</span
    >
    <span
      v-if="isFavorite"
      class="hidden lg:group-hover:block lg:group-hover:pl-1"
      >Remove from favourites</span
    >

    <NuxtImg
      width="24px"
      height="24px"
      src="/icon/favourites.svg"
      alt="Remove from favorites"
      class="hidden lg:group-hover:block"
    />

    <NuxtImg
      v-if="!isFavorite"
      width="24px"
      height="24px"
      src="/icon/add-to-favourites.svg"
      alt="Add to favorite"
      class="lg:group-hover:hidden"
    />

    <NuxtImg
      v-if="isFavorite"
      width="24px"
      height="24px"
      src="/icon/favourites.svg"
      alt="Remove from favorites"
      class="lg:group-hover:hidden"
    />
  </div>
</template>
